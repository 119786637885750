.contact-section{
    padding-bottom: 6.25rem;
}

.contact_container{
  grid-template-columns: 4fr 8fr;
  column-gap: 1.875rem;
}

.contact_title{
    font-size: var(--h3-font-size);
    margin-bottom: .5rem;
}

.contact_form-group{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 1.5rem;
}

.contact_form-div{
    position: relative;
    margin-bottom: 1.875rem;
    height: 3.75rem;
}

.contact_form-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: var(--shadow);
    background-color: var(--container-color);
    color: var(--text-color);
    border: none;
    outline: none;
    border-radius: var(--border-radius);
    padding: 0.625rem 1.875rem;
    z-index: 1;
}

.contact_form-area{
height: 10.25rem;
}

.contact_form-area textarea{
 resize: none;
}

@media screen and (max-width:1024px) {
    .contact_container{
        grid-template-columns: 300px 360px;
        justify-content: center;
    }

    .contact_form-group{
        grid-template-columns: 1fr;
    }

}

@media screen and (max-width:768px) {
    .contact_container{
        grid-template-columns: 310px;
        row-gap: 1.875rem;
    }

    .contact_info{
        text-align: center;
    }
}


@media screen and (max-width:350px) {
    .contact_container{
        grid-template-columns: 1fr;
    }
}