.resume_container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-gap: 2rem;
  }
  .timeline {
    padding: 1rem;
    text-align: center;
    border: 1px solid;
    border-radius: 1rem;
    background: #00d9ff;
    transition: 0.8s ease-in;
    animation: color 9s infinite linear;
  
  }
  
  .timeline:hover {
    cursor: pointer;
    /* background: #00fffa; */
    font-size: 1.1rem;

  
  }

  .timeline h2,h3,h4{
    line-height: 2;
  } 


@keyframes color {
  0%   { background: #CFF800; }
  20%  { background: #B4EBE6; }
  40%  { background: #FEFF92; }
  60%  { background: #C6C5B3; }
  80%  { background: #0FEFBD; }
  100% { background: #6A9C89; }
}



@media screen and (max-width:1024px) {
    
    .resume_container{
        grid-template-columns: repeat(2, 330px);
        justify-content: center;
        row-gap: 1.875rem;
    }
}

@media screen and (max-width:768px) {
    .resume_container{
        grid-template-columns: 310px;
    }

   
}

@media screen and (max-width:350px) {
    .resume_container{
        grid-template-columns: 1fr;
    }
}

